// Create vh measurement property
let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

window.addEventListener('resize', () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
});

// Perform backend check on API
let vloc = window.location;
if (vloc.pathname !== "/" && vloc.pathname !== undefined && vloc.pathname !== "") {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', '/api/isExists' + vloc.pathname, true);
    xhr.onload = () => {
        console.log("isExists_resp:", xhr.responseText);
        if (xhr.responseText === "false") {
            showMessage('Your video is not available');
        } else if (xhr.status == 502) {
            showMessage('Your video is not available 502');
        }
    };
    xhr.send(null);
} else {
    showMessage('Your video is not available');
}

// Form Submit
const formSubmit = document.getElementById('submit');
formSubmit.addEventListener('click', onFormSubmit);

function onFormSubmit(e) {
    e.preventDefault();

    let error = false;

    const email1 = document.getElementById('email1');
    const email2 = document.getElementById('email2');

    email1.classList.remove('error');
    email2.classList.remove('error');

    if (email1.value == '' || !validateEmail(email1.value)) {
        email1.classList.add('error');
        error = true;
    }

    if (email2.value == '' || !validateEmail(email2.value)) {
        email2.classList.add('error');
        error = true;
    }

    if (!error) {
        if (!compareEmails(email1.value.toLowerCase(), email2.value.toLowerCase())) {
            email1.classList.add('error');
            email2.classList.add('error');
        } else {
            sendMail(email1.value.toLowerCase());
        }
    }
}

function compareEmails(email1, email2) {
    if (email1 == email2 ) {
        console.log('emails match');
        return true;
    } else {
        console.log('emails do not match');
        return false;
    }
}

function validateEmail(email) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        console.log(email + ' is valid');
        return true;
    } else {
        console.log(email + ' is not valid');
        return false;
    }
}

function sendMail(recipient) {
    console.log("submit form as emails match and are valid --> ", recipient);
    const xhr = new XMLHttpRequest();
    xhr.open('POST', '/api/sendMail' + vloc.pathname, true);
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.onload = () => {
        console.log("sendMail_resp:", xhr.responseText);
        if (xhr.status == 502) {
            showMessage('Error 502');
        } else if (xhr.responseText !== "true") {
            showMessage('Unable to send the video'); 
        } else {
            showMessage('Thanks, your video has been sent', false);
        }
    };
    xhr.send("recipient=" + recipient);
}

function showMessage(message, showCopy = true) {
    const title = document.getElementById('title');
    const copy = document.getElementById('copy');
    const form = document.getElementById('form');

    form.classList.add('dn');
    title.textContent = message;

    if (showCopy) {
        const link = document.createElement("a");
        link.setAttribute('href', 'mailto:info@mrmoco.com');
        link.textContent = 'info@mrmoco.com';
    
        copy.textContent = "Please ask for assistance or contact us";
        copy.appendChild(link);
    } else {
        copy.textContent = "";
    }
    
}